<template>
  <v-app>
    <v-container fluid style="padding-left: 0px;padding-bottom: 0px;padding-top: 0px;padding-right: 0px;">
        <link rel="stylesheet" href="/hyireco/vendor/purpose/assets/css/purpose.css" id="stylesheet" />

        <body class="page_login">
        <header class="header">
            <div class="container js-header-wrapper"  style="margin-left: 0;">
                <div class="row justify-content-between align-items-center">
                    <div class="col-auto">
                        <a href="login.html" class="logo"><h1 class="mb-0"><img src="images/logo.png" alt="人員進出自動辨識系統 | 凌網科技"></h1></a>
                    </div>

                    <div class="col-xl-auto sidenav-toggler-content" style="padding-top: 0px;padding-bottom: 0px;position: absolute;right:0px;" v-if="false">
                    <div class="nav-wrapper">
                        <div class="d-flex align-items-end flex-column">
                            <div class="col-12 order-1 order-xl-1 zindex-2">
                                <div class="top_header">
                                    <div class="row">
                                        <div class="col-12 col-xl-auto mb-2 mb-xl-0" style="padding-bottom: 0px;padding-top: 0px;">
                                            <ul class="nav-secondary mb-0">
                                                <li><a href="#" class="nav-item">聯絡我們</a></li>
                                                <li><a href="#" class="nav-item">常見問題</a></li>
                                            </ul>
                                        </div>
                                        <div class="col-12 col-xl-auto" style="padding-bottom: 0px;padding-top: 0px;">
                                            <ul class="nav-lang mb-0">
                                                <li><a href="#" class="nav-item">English</a></li>
                                                <li><a href="#" class="nav-item active">中文</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-12 order-2 order-xl-2 flex-0"  style="padding-bottom: 0px;padding-top: 0px;" v-if="false">
                                <nav class="nav-primary">
                                    <ul class="row mb-0">
                                        <li class="col-12 col-xl-auto nav-dropdown"  style="padding-bottom: 0px;padding-top: 0px;">
                                            <button class="dropdown-btn">
                                                <div class="row no-gutters align-items-center justify-content-between justify-content-xl-start">
                                                    <div class="col-auto mr-2">
                                                        <h2 class="dropdown-btn-headline">下載專區</h2>
                                                    </div>
                                                    <div class="col-auto" >
                                                        <span class="dropdown-btn-icon"><i class="fas fa-angle-down"></i></span>
                                                    </div>
                                                </div>
                                            </button>
                                            <ul class="dropdown-menu mb-0" >
                                                <li>
                                                    <a href="#" class="menu-item">
                                                        <h3 class="menu-item-headline">第二層選單</h3>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" class="menu-item">
                                                        <h3 class="menu-item-headline">第二層選單</h3>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="col-12 col-xl-auto nav-dropdown"  style="padding-bottom: 0px;padding-top: 0px;">
                                            <button class="dropdown-btn">
                                                <div class="row no-gutters align-items-center justify-content-between justify-content-xl-start">
                                                    <div class="col-auto mr-2">
                                                        <h2 class="dropdown-btn-headline">關於我們</h2>
                                                    </div>
                                                    <div class="col-auto">
                                                        <span class="dropdown-btn-icon"><i class="fas fa-angle-down"></i></span>
                                                    </div>
                                                </div>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a href="#" class="menu-item">
                                                        <h3 class="menu-item-headline">第二層選單</h3>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" class="menu-item">
                                                        <h3 class="menu-item-headline">第二層選單</h3>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>                        
                                
                            </div>
                        </div>
                    </div>
                </div>

                    
                    <div class="d-block d-xl-none col-auto p-0" v-if="false">
                        <!-- Sidenav toggler (for pad/mobile) -->
                        <div class="sidenav-toggler" data-action="sidenav-unpin" data-target="#sidenav-toggler-content">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line bg-dark"></i>
                                <i class="sidenav-toggler-line bg-dark"></i>
                                <i class="sidenav-toggler-line bg-dark"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <main class="main">
            <section class="banner">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col">
                            <div class="banner-title text-center text-lg-left mb-4 mb-lg-0">
                                <h1 class="head">
                                    <p class="mb-0 mb-sm-2">歡迎使用</p>
                                    <p>人員進出自動辨識系統</p>
                                </h1>
                                <h6 class="subhead mt-2 mt-sm-4 px-4 px-sm-0">透過人員進出自動辨識系統，有效管理進出人員，精準掌握人員動向。</h6>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-4">
                            <div class="card mb-0">
                                <div class="card-header">
                                    <h3 class="login-title text-center text-lg-left mb-0">登入</h3>
                                </div>
                                <div class="card-body">
                                    <form>
                                        <div class="row justify-content-center">
                                            <div class="col-12" style="padding-top: 0px;padding-bottom: 0px;">
                                                <div class="form-group">
                                                    <!-- <label class="col-form-label text-sm">帳號</label> 
                                                    <input type="text" class="form-control text-sm" placeholder="請輸入帳號" v-model="userID">-->

                                                     <v-text-field
                                                        label="請輸入帳號"
                                                        v-model="userID"
                                                        outlined
                                                        background-color="#FFFFFF"
                                                        hide-details
                                                        class="text-sm-h5"
                                                    ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-12" style="padding-top: 0px;padding-bottom: 0px;">
                                                <div class="form-group">
                                                    <!-- <label class="col-form-label text-sm">密碼</label> 
                                                    <input type="password" class="form-control text-sm" placeholder="請輸入密碼" v-model="userPW">-->
                                                    <v-text-field
                                                        :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="showPW ? 'text' : 'password'"
                                                        label="請輸入密碼"
                                                        v-model="userPW"
                                                        outlined
                                                        background-color="#FFFFFF"
                                                        @click:append="showPW = !showPW"
                                                        hide-details
                                                        class="text-sm-h5"
                                                    ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-12" style="padding-top: 0px;padding-bottom: 0px;">
                                                <v-row class="form-group">
                                                    <!-- <label class="col-form-label text-sm">驗證碼</label> -->
                                                    <v-col class="mt-2" sm="2"><img :src="imgSrc"/></v-col>
                                                    <v-col>
                                                        <v-text-field
                                                            label="請輸入驗證碼"
                                                            v-model="captcha"
                                                            outlined
                                                            background-color="#FFFFFF"
                                                            hide-details
                                                        ></v-text-field>
                                                    </v-col>
                                                    
                                                </v-row>
                                            </div>
                                            <div class="col-12" style="padding-top: 0px;padding-bottom: 0px;" v-if="false">
                                                <div class="form-group row align-items-center">
                                                    <label class="col-12 col-sm-3 text-sm">登入模式</label>
                                                    <div class="col">
                                                        <label class="mb-0 mr-4">
                                                            <input type="radio" class="" name="login_type" value="M" v-model="loginMode">
                                                            <span>後台管理</span>
                                                        </label>
                                                        <label class="mb-0">
                                                            <input type="radio" class="" name="login_type" value="R" v-model="loginMode">
                                                            <span>人員辨識</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center" >
                                            <div class="col-12"  style="padding-top: 0px;">
                                                <button type="button" class="btn btn-primary w-100" @click="toLogin">登入</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="news" >
                <div class="container">
                    <div class="row">
                        <div class="col-12 px-4C">
                            <h2 class="news-title">最新消息</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 px-4" style="padding-top: 0px;padding-bottom: 0px;">
                            <ul class="news-lists mb-0">
                                <li class="py-2 py-md-1">
                                    <div class="row">
                                        <div class="col-12 col-md-2 col-xl-1">
                                            <span>2021/03/31</span>
                                        </div>
                                        <div class="col">
                                            <span>最新消息：人員進出自動辨識系統於2021/3/31正式上線囉~</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer>
            <div class="footer" style="padding-top: 16px;padding-bottom: 16px; background-color:#FFF" v-if="false">
                <div class="container">
                    <div class="row justify-content-center justify-content-md-between align-items-center">
                        <div class="col-auto mb-3 mb-md-0">
                            <div class="logo"><img src="images/logo.png" alt="Logo"></div>
                        </div>
                        <div class="col-auto">
                            <nav class="nav-footer">
                                <ul class="row mb-0">
                                    <li class="col-4 col-sm-auto"><a href="#" class="nav-item"><h3 class="nav-item-headline">最新消息</h3></a></li>
                                    <li class="col-4 col-sm-auto"><a href="#" class="nav-item"><h3 class="nav-item-headline">下載專區</h3></a></li>
                                    <li class="col-4 col-sm-auto"><a href="#" class="nav-item"><h3 class="nav-item-headline">關於我們</h3></a></li>
                                    <li class="col-4 col-sm-auto"><a href="#" class="nav-item"><h3 class="nav-item-headline">資源</h3></a></li>
                                    <li class="col-4 col-sm-auto"><a href="#" class="nav-item"><h3 class="nav-item-headline">常見問題</h3></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="under_footer">
                <div class="container">
                    <div class="row justify-conent-left justify-content-sm-center">
                        <div class="col-12 col-sm-auto px-3 px-sm-0">
                            <h5 class="nav-item-headline">2021-凌網科技股份有限公司</h5>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <button id="btn-scrollTop" title="回到最上面"><i class="fas fa-angle-up"></i></button>

      </body>
    </v-container>

    <loadingMask :showLoading="isLoading"></loadingMask>
    <alterDialog :showDialog="dialog" :dialogMessage="message" @dialogEvent="dialog=$event" />
  </v-app>
</template>

<script>
import commonMixin from '@/utils/commonMixin';
import {apLogin} from "@/api/authApi";
import uuidv4 from 'uuid/v4'
import loadingMask from '@/components/public/loadingMask.vue'
import alterDialog from '@/components/public/alterDialog.vue'

export default {
  name: 'LoginPage',
  mixins: [commonMixin],
  created(){
    console.log("start login =" + window.sessionStorage.getItem('LoginAuth'));
    this.loginAuth = {};
    window.sessionStorage.setItem('LoginAuth', JSON.stringify(this.loginAuth));
    console.log("clean login =" +  window.sessionStorage.getItem('LoginAuth'));
  },
  mounted(){
    this.loginMode = "M";
    this.domain = window.location.origin === "http://localhost:8081" ? "http://10.10.66.124" : window.location.origin;
    var d = new Date();
    var n = d.getTime();
    this.imgSrc = this.domain + "/HyirecoM/codeImageServlet?s=" + n
    this.closeCamera();
  },
  methods: {
    closeCamera() {
      if (typeof window.stream === 'object') {
        if ('srcObject' in this.video) {
          this.video.srcObject = null
        }
        window.stream.getTracks().forEach(track => track.stop())
      }
    },
    toLogin: function() {
        let vm = this;
        vm.isLoading = true;
        var apiPara = new URLSearchParams();
        apiPara.append("account", vm.userID);
        apiPara.append("pwd", vm.userPW);
        apiPara.append("verifyCode", vm.captcha);
        apiPara.append("loginMode", "M");

        const csrfToken = uuidv4();
        const header = {
            header: {'X-XSRF-TOKEN': csrfToken} 
        }
        apLogin(apiPara, header).then(function(response) {
            //console.log("response Login = " + JSON.stringify(response));
            if ("201" == response.status) {
                
                //加值服務處理
                var plusFunc = [];
                vm.plusFuncArray.forEach(item => {
                    var objValue = "";
                    try { //避免cannot read property of undefined
                        objValue = response.data.memberProfile.orgnization[item];
                    } catch (e) {
                        objValue = e;
                    }

                    if (objValue === "Y") {
                        plusFunc.push(item);
                    }
                });
                //console.log("plusFunc = " + JSON.stringify(plusFunc));

                vm.loginAuth = {
                    uuid: response.data.memberProfile.uuid,
                    userID: vm.userID,
                    memberName: response.data.memberProfile.name,
                    role: response.data.memberProfile.role,
                    id_token: response.data.id_token,
                    refreshId: response.data.refreshId,
                    userTree: response.data.authFuncTreeNode,
                    orgnization: response.data.memberProfile.orgnization,
                    subUnitRelationshipList: response.data.memberProfile.subUnitRelationshipList,
                    plusFunc: plusFunc,
                    timeStart: Date.now(),
                };

                if (vm.loginAuth.role === "S") {
                    vm.loginAuth.plusFunc = vm.plusFuncArray;
                }

                if (vm.loginAuth.role === "O") {
                    vm.loginAuth.subUnitRelationshipList = response.data.memberProfile.orgnization.subUnitList;
                }

                window.sessionStorage.setItem('LoginAuth', JSON.stringify(vm.loginAuth));
                vm.isLoading = false;
                
                //this.location.href = "/index";
                vm.$router.push({ path: "/index" });
            } else {
                vm.message = "網路發生問題";
                vm.dialog = true;
                vm.isLoading = false;
            }
        }).catch(function (error) {
            console.log(error);
            vm.isLoading = false;
            var errResponse = error.response;
            vm.dialog = true;
            if (typeof errResponse === 'undefined') {
                vm.message = "帳號或密碼錯誤";
            } else {
                vm.message = errResponse.data.msg;
            }
            
            var d = new Date();
            var n = d.getTime();
            vm.imgSrc = vm.domain + "/HyirecoM/codeImageServlet?s=" + n
            vm.captcha = "";
        });
    },
  },
  watch: {
  },
  data: () => ({
    userID: "",
    userPW: "",
    captcha: "",
    loginMode: "",
    isLoading: false,
    loginAuth: null,
    dialog: false,
    message: "",
    domain: "",
    imgSrc: "",
    showPW: false,
    plusFuncArray: [
        "oscInterfaceEnable", //臺灣職安卡介接資料
        "heatindexEnable", //熱指數儀表板
        "thEnable", //溫濕度儀表板
        "keepworkAlertEnable", //連續七日入場警示
        "smartPlugEnable", //智慧插座燈號
        "voiceAlarmEnable", //語音提示
    ],
  }),
  components: {
    loadingMask,
    alterDialog,
  },
}
</script>

<style scoped>

</style>