<template>
    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-card-title>系統訊息</v-card-title>
        <v-card-text>{{dialogMessage}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "alterDialog",
  props: ['showDialog', 'dialogMessage'],
  data: () => ({
      
  }),
  methods: {
    closeDialog: function() {
        this.$emit('dialogEvent', false);
    }
  }
};
</script>